import "styles/pages/cookies-policy.scss";

import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import BreadcrumbsPolicy from "components/BreadcrumbsPolicy";

const CookiesPolicy = ({ data }) => {

    const header_setup = {logo:'logo_black.svg', fontColor:'#000000', navbarColor: '#ffffff'};
  
    const seo = data.pageCookiesPolicy?.nodes[0]?.seo;
    const cookies_policy_page_data = data.pageCookiesPolicy?.nodes[0]?.pageCookiesPolicy;

    return (
        <Layout seo={seo} header_setup={header_setup}>
            <section className="cookies-policy">
                <div className="cookies-policy__container">
                    <div className="section-title">{cookies_policy_page_data.cookiesTitle}</div>
                    <div className="cookies-policy__content">
                    <BreadcrumbsPolicy parent="Strona główna" current="Polityka Cookies" />
                    <div dangerouslySetInnerHTML={{ __html: cookies_policy_page_data.cookiesContent }} />
                    </div>
                </div>
            </section>
        </Layout>
  );
};

export const query = graphql`
  {
    pageCookiesPolicy: allWpPage(filter: {databaseId: {eq: 619}}) {
      nodes {
        seo {
          metaDesc
          title
        }
        pageCookiesPolicy {
          cookiesContent
          cookiesTitle
        }
      }
    }
  }
`

export default CookiesPolicy;