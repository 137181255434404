import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

const BreadcrumbsPolicy = ({ current, parent }) => {

    return (
    <div className="breadcrumbs">
      <div className="container">
        <Link to="/">{parent}</Link>
        <span>{current}</span>
      </div>
    </div>
  );
};

export default BreadcrumbsPolicy;